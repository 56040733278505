import logo from './logo.svg';
import './App.css';
import { AiOutlineInstagram } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import Photos from './Photos';
import { useEffect, useState } from 'react'
function App() {
  return (
    <div className="App">
      <aside className="sidebar">
        <div className="description">leiannes.photos</div>
        <div className="leianne">L</div>
        <div className="personal-sites">
          <a href="https://itsleianne.com" aria-label="itsleianne.com" rel="noreferrer" target="_blank"><BsPerson/></a>
          <a href="https://instagram.com/__leianne_" aria-label="@__leianne_ on instagram" rel="noreferrer" target="_blank"><AiOutlineInstagram/></a>
        </div>
      </aside>
    <Photos/>
    </div>
  );
}

export default App;
