import Flickity from 'react-flickity-component'
import "./flickity.css";
import { useEffect, useState } from 'react';
const { REACT_APP_UNSPLASH_APP_ID } = process.env;

export default function Photos() {
  const [photos, setPhotos] = useState(null)
  useEffect(() => {
    fetch(`https://api.unsplash.com/users/__leianne_/photos?client_id=${REACT_APP_UNSPLASH_APP_ID}`)
      .then(response => response.json())
      .then(data => setPhotos(data));
  }, [])
  console.log(photos)

 return (
    <div className="carousel">
      <Flickity>
        {photos != null && (
          photos.map(photo => {
            console.log(photo)
            return  <div className="photoContainer"><img alt={photo.description} className="responsive" src={photo?.urls?.regular} /></div>
          })
        )}
      </Flickity>
    </div>

  )
}